import React from 'react'
import clsx from 'clsx'
import { MediaQueryType } from './types/MediaQuery.types'
import styles from './styles/MediaQuery.module.scss'

const MediaQuery: React.FC<MediaQueryType> = props => {
  const { children, className, size, ...otherProps } = props

  return (
    <div className={clsx(className, !!size && styles[size])} {...otherProps}>
      {children}
    </div>
  )
}

export default MediaQuery
