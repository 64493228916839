import styled from '@mui/material/styles/styled'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { seeDetailsColor, seeDetailsPositionVertical } from '../../../utils/termsAndConditions'
import { StyledButtonAsLink, StyledToast } from '../../UI'

export const StyledTermsConditionWrapper = styled('div', {
  name: 'TermsAndConditionsV2',
  slot: 'Wrapper',
  shouldForwardProp: props => props !== 'alignment',
})<{
  alignment?: string
}>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  pointerEvents: 'auto',
  paddingTop: theme.spacing(4),
  width: '100%',
}))

export const StyledTermsFullWidthConditionWrapper = styled('div', {
  name: 'TermsAndConditionsV2',
  slot: 'FullWidthWrapper',
})(({}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
}))

export const StyledTermsToast = styled(StyledToast, {
  name: 'TermsAndConditionsV2',
  slot: 'Toast',
})(({}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  padding: 0,
}))

export const StyledTermsTwoColumnConditionWrapper = styled(StyledTermsFullWidthConditionWrapper, {
  name: 'TermsAndConditionsV2',
  slot: 'TwoColumnWrapper',
})(({ theme }) => ({
  [theme.breakpoints.down(769)]: {
    display: 'none',
    margin: 'auto',
  },
  [theme.breakpoints.up(769)]: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
  },
}))

export const StyledDetailsInfo = styled(StyledButtonAsLink, {
  name: 'TermsAndConditionsV2',
  slot: 'Details',
  shouldForwardProp: prop =>
    prop !== 'seeDetailsPosition' &&
    prop !== 'isBlockCenter' &&
    prop !== 'isBoxWithMargin' &&
    prop !== 'bgColor' &&
    prop !== 'viewtype',
})<{
  seeDetailsPosition?: number | string
  isBlockCenter?: boolean
  isBoxWithMargin?: boolean
  bgColor?: string
  viewtype?: IViewType
}>(({ theme, seeDetailsPosition, isBlockCenter, viewtype, bgColor, isBoxWithMargin }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: seeDetailsColor(bgColor, theme),
  cursor: 'pointer',
  textDecoration: 'none',
  ...seeDetailsPositionVertical(theme, viewtype),

  '&::after': {
    display: 'none',
  },

  [theme.breakpoints.up(769)]: {
    color: isBlockCenter ? theme.palette.custom.light.blue : seeDetailsColor(bgColor, theme),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 600,
    position: 'absolute',
    right: theme.spacing(6),
    bottom: theme.spacing(4),
    '&:hover': {
      color: theme.palette.text.light.primary,
    },
  },

  [theme.breakpoints.down(769)]: {
    color: seeDetailsColor(bgColor, theme),
    bottom: !isBoxWithMargin && viewtype !== 'box-without-margin' && theme.spacing(0),
  },
}))
