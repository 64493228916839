import dateService from '@services/DateService'
import { TPrice, TPriceServer, TProductPrice } from '../index.types'

export const formatPercentage = (percentage: number, pattern: string | undefined): string => {
  if (!pattern) {
    return percentage.toFixed(0) + '%'
  }

  const formattedPercentage = percentage.toFixed(0)
  const containsSpace = pattern.includes(' ')
  const percentageSymbolPosition = pattern.endsWith('#') ? 'left' : 'right'

  if (percentageSymbolPosition === 'left') {
    return ['%', formattedPercentage].join(containsSpace ? ' ' : '')
  }

  return [formattedPercentage, '%'].join(containsSpace ? ' ' : '')
}

export const formatPrice = (price: number, pattern: string | undefined): string => {
  if (!pattern) {
    return price.toFixed(2)
  }

  const currencySymbol = pattern.endsWith('#') ? pattern.charAt(0) : pattern.charAt(pattern.length - 1)
  const currencySymbolPosition = pattern.endsWith('#') ? 'left' : 'right'
  const decimalSeparator = pattern.includes('.') ? '.' : ','
  const containsSpace = pattern.includes(' ')
  const formattedPrice = price.toFixed(2).replace('.', decimalSeparator)

  if (currencySymbolPosition === 'left') {
    return [currencySymbol, formattedPrice].join(containsSpace ? ' ' : '')
  }

  return [formattedPrice, currencySymbol].join(containsSpace ? ' ' : '')
}

export const setFinalPrices = (price: TPrice | null): TPrice | null => {
  if (!price?.offerPrice && !price?.listPrice) {
    return null
  }

  if (!price.offerPrice && price.listPrice) {
    return {
      ...price,
      listPrice: price.listPrice,
      offerPrice: price.listPrice,
      percentageDiscount: 0,
      showListPrice: false,
    }
  }

  if (price.offerPrice && !price.listPrice) {
    return {
      ...price,
      percentageDiscount: 0,
      listPrice: price.offerPrice,
      offerPrice: price.offerPrice,
      showListPrice: false,
    }
  }

  if (
    price.offerPrice &&
    price.listPrice &&
    (price.offerPrice < price.listPrice || price.offerPrice === price.listPrice)
  ) {
    return {
      ...price,
      showListPrice: !!(
        price?.percentageDiscount &&
        price?.percentageDiscount > 0 &&
        price.listPrice &&
        price.listPrice > 0
      ),
    }
  } else {
    return {
      ...price,
      listPrice: price.segment === 'Guest' ? price.listPrice : price.offerPrice,
      offerPrice: price.segment === 'Guest' ? price.listPrice : price.offerPrice,
      percentageDiscount: 0,
      showListPrice: false,
    }
  }
}

export const sortByPriceListPrecedenceAndOfferPrice = (a: TPrice | null, b: TPrice | null): number => {
  if (
    !a ||
    !b ||
    a.priceListPrecedence === undefined ||
    b.priceListPrecedence === undefined ||
    a.precedence === undefined ||
    b.precedence === undefined ||
    a.offerPrice === undefined ||
    b.offerPrice === undefined
  ) {
    return 0
  }

  if (a.priceListPrecedence === b.priceListPrecedence) {
    if (a.precedence === b.precedence) {
      return a.offerPrice - b.offerPrice
    }
    return b.precedence - a.precedence
  }

  return b.priceListPrecedence - a.priceListPrecedence
}

export const getValidPrices = (prices: TProductPrice['prices']): TPrice[] => {
  const internalPrices: TPrice[] = []

  for (const priceListKey in prices) {
    const priceServer = prices[priceListKey]
    const price = priceMapper(priceServer, priceListKey, false)

    if (isNowInRange(price.startDate, price.endDate)) {
      internalPrices.push(price)
    }

    if (price.futurePrices?.length) {
      price.futurePrices.forEach(futurePriceServer => {
        const futurePrice = priceMapper(futurePriceServer, priceListKey, true)

        if (isNowInRange(futurePrice.startDate, futurePrice.endDate)) {
          internalPrices.push(futurePrice)
        }
      })
    }
  }

  return internalPrices
}

export const priceMapper = (price: TPriceServer, priceListKey: string, isFuturePrice: boolean): TPrice => {
  return {
    ...price,
    offerPrice: typeof price.offerPrice === 'string' ? Number.parseFloat(price.offerPrice) : price.offerPrice,
    listPrice: typeof price.listPrice === 'string' ? Number.parseFloat(price.listPrice) : price.listPrice,
    amountOfDiscount:
      typeof price.amountOfDiscount === 'string' ? Number.parseFloat(price.amountOfDiscount) : price.amountOfDiscount,
    percentageDiscount:
      typeof price.percentageDiscount === 'string'
        ? Number.parseFloat(price.percentageDiscount)
        : price.percentageDiscount,
    precedence: typeof price.precedence === 'string' ? Number.parseFloat(price.precedence) : price.precedence,
    priceListPrecedence:
      typeof price.priceListPrecedence === 'string'
        ? Number.parseFloat(price.priceListPrecedence)
        : price.priceListPrecedence,
    priceListKey,
    isFuturePrice,
  }
}

export const getSelectedPrice = (prices: TProductPrice['prices'] | null, customerSegments: string[]): TPrice | null => {
  if (!prices) {
    return null
  }

  const filteredPrices = getValidPrices(prices)
    .filter(price => customerSegments.includes(price.segment ?? ''))
    .sort(sortByPriceListPrecedenceAndOfferPrice)

  if (filteredPrices.length === 0) {
    return null
  }

  return filteredPrices[0]
}

export const getProductPriceByCustomerSegments = (
  prices: TProductPrice['prices'] | null,
  customerSegments: string[]
) => {
  const selectedPrice = getSelectedPrice(prices, customerSegments)
  return setFinalPrices(selectedPrice)
}

export const isNowInRange = (startDate: string | undefined, endDate: string | undefined): boolean => {
  if (!startDate || !endDate) {
    return false
  }

  const parsedStartDate = dateService.convertPriceDate(startDate)
  const parsedEndDate = dateService.convertPriceDate(endDate)

  const start = new Date(parsedStartDate)
  const end = new Date(parsedEndDate)
  const now = new Date()

  return now >= start && now <= end
}
