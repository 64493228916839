import config from '@configs/index'
import { useSite } from '@foundation/hooks/useSite'
import { Attachment, ProductImageUsage } from '@typesApp/product'
import dynamic from 'next/dynamic'
import { ParsedQuery } from 'query-string'
import React, { MouseEvent, ReactEventHandler, TouchEvent } from 'react'
import { Image } from './Image'
import { addAkamaiParameters, generateProductImagePath, generateSrcSet, isVideoURL } from './helpers'

const VideoPlayer = dynamic(
  () => import('@components/Cms/CmsComponents/CmsCommonMedia/VideoPlayer').then(m => m.VideoPlayer),
  { ssr: false }
)

type ProductImage = {
  width?: number
  alt?: string
  attachments?: Attachment[]
  srcSetMap?: Record<number, string>
  sequence?: string
  usage?: ProductImageUsage
  isFramesProduct?: boolean
  isAccessoriesProduct?: boolean
  backgroundColor?: string
  attr?: ParsedQuery<string>
  lazy?: boolean
  preload?: boolean
  fetchpriority?: 'auto' | 'high' | 'low'
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
  controls?: boolean
  shouldLoad?: boolean
  onMouseMove?: (e: MouseEvent<HTMLImageElement>) => void
  onTouchMove?: (e: TouchEvent<HTMLImageElement>) => void
  onLoad?: ReactEventHandler<HTMLImageElement>
  fallback?: {
    usage: ProductImageUsage
    sequence?: string
  }
}

export const ProductMedia: React.FC<ProductImage> = ({
  width,
  attachments,
  srcSetMap,
  alt,
  usage,
  sequence,
  isFramesProduct,
  isAccessoriesProduct,
  backgroundColor,
  lazy,
  attr,
  preload = false,
  fetchpriority = 'auto',
  style,
  className,
  controls,
  shouldLoad,
  onLoad,
  fallback,
  ...rest
}) => {
  const { mySite: site } = useSite()
  const damDomain: string = site?.xStoreCfg?.['damDomain'] || config.defaultDamDomain
  const baseURL = generateProductImagePath(
    damDomain,
    attachments,
    usage,
    sequence,
    isAccessoriesProduct,
    isFramesProduct,
    fallback
  )
  const isVideo = isVideoURL(baseURL)
  const src = addAkamaiParameters(
    baseURL,
    width || Number(config.productImageAkamaiDefaultWidth),
    backgroundColor,
    attr
  )
  const srcset = !isVideo ? generateSrcSet(baseURL, srcSetMap, backgroundColor, attr) : undefined

  if (isVideo) {
    return (
      <div className="media-wrapper is-video">
        <VideoPlayer
          url={`${src}#t=0.1`}
          width="100%"
          height="100%"
          style={{ position: 'relative' }}
          controls={controls}
          playsinline
          shoudLoad={true} // TODO: once we have a proper solution for video lazy loading, we can remove this prop
          isInView={true}
          {...rest}
        />
      </div>
    )
  }

  return (
    <div className="media-wrapper">
      <Image
        key={src}
        width={Number(config.productImageRatioWidth)}
        height={Number(config.productImageRatioHeight)}
        alt={alt}
        src={src}
        srcSet={srcset}
        lazy={lazy}
        preload={preload}
        fetchPriority={fetchpriority}
        style={style}
        className={className}
        shoudLoad={shouldLoad}
        onLoad={onLoad}
        {...rest}
      />
    </div>
  )
}
