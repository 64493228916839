import { isEmpty } from '@utils/helpers'
import { useTranslation } from 'next-i18next'
import { TPriceDiscount, TProductPrice } from './index.types'
import { formatPercentage, formatPrice, getProductPriceByCustomerSegments } from './utils/utils'
import { ConditionalWrapper } from '@components/Cms/CmsComponents-CSS/Media/ConditionalWrapper'
import styles from './styles/index.module.scss'

const PriceDiscountWithoutI18n: React.FC<TPriceDiscount & { offLabel: string }> = ({
  amountOfDiscount,
  percentageDiscount,
  priceSetting,
  discountSetting,
  offLabel,
}) => {
  const discountAmount = (discountSetting.type === 'amount' ? amountOfDiscount : percentageDiscount) || 0
  const formatterFunc = discountSetting.type === 'amount' ? formatPrice : formatPercentage
  const pattern = discountSetting.type === 'amount' ? priceSetting?.pattern : discountSetting.pattern || '##%'

  if (discountAmount <= 0) return null

  return (
    <div className={'discount-wrapper'}>
      <span className={'markdown'}>{`${formatterFunc(discountAmount, pattern)} ${offLabel}`}</span>
    </div>
  )
}

const PriceDiscount: React.FC<TPriceDiscount> = props => {
  const { t } = useTranslation()
  const OffLabel = t(props.discountSetting?.offLabelKey ?? '', {
    number: props.percentageDiscount,
  })

  return <PriceDiscountWithoutI18n {...props} offLabel={OffLabel} />
}

export const ProductPrice: React.FC<TProductPrice> = ({
  prices,
  customerSegments,
  discountSetting,
  priceSetting,
  prefixLabel,
  strikethrough = true,
}) => {
  const {
    isFuturePrice,
    listPrice,
    offerPrice,
    percentageDiscount,
    priceListKey: priceKey,
    segment,
    showListPrice,
    amountOfDiscount,
  } = getProductPriceByCustomerSegments(prices, customerSegments) || {}

  const pricePattern = priceSetting?.pattern || '$##.##'

  return (
    <div
      className={styles['product-price']}
      data-segment={segment}
      data-price-list={priceKey}
      data-future-price={isFuturePrice}
    >
      {prefixLabel && <span className="price-prefix">{prefixLabel}</span>}

      {showListPrice && listPrice && (
        <ConditionalWrapper condition={true} className="list-price" wrapperTag={strikethrough ? 's' : 'span'}>
          {formatPrice(listPrice, pricePattern)}
        </ConditionalWrapper>
      )}

      {offerPrice && <span className="offer-price">{formatPrice(offerPrice, pricePattern)}</span>}

      {!isEmpty(discountSetting) && (
        <PriceDiscount
          amountOfDiscount={amountOfDiscount}
          percentageDiscount={percentageDiscount}
          priceSetting={priceSetting}
          discountSetting={discountSetting}
        />
      )}
    </div>
  )
}
