import React from 'react'

export const ToastThemeVariantValues = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary'
}

export type ToastThemeVariant = keyof typeof ToastThemeVariantValues

export interface ToastProp {
  open?: boolean
  isThinMargin?: boolean
  themeVariant?: ToastThemeVariant,
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  headingText?: string
  body?: string | JSX.Element
  children?: React.ReactNode
  isMobile?: boolean
}
