import React from 'react'
import {
  ToastWrapper,
  ToastContainer,
  ToastHeader,
  ToastCloseButton,
  ToastHeading,
  ToastCustomCloseButton,
  ThinMarginToastWrapper,
} from './Toast.style'
import type { ToastProp } from './Toast.type'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const StyledToast: React.FC<ToastProp> = ({
  headingText,
  onClose,
  body,
  themeVariant,
  open,
  children,
  isThinMargin = false,
}: ToastProp) => {
  const isCustomContent = !!children
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose && onClose(e)
  }

  const Wrapper = isThinMargin ? ThinMarginToastWrapper : ToastWrapper

  const { isMobile } = useBreakpoints()

  return (
    <>
      <Wrapper themeVariant={themeVariant} open={open} isMobile={isMobile} className="toast">
        {!isCustomContent ? (
          <>
            <ToastHeader>
              <ToastHeading variant={'h4'}>{headingText}</ToastHeading>
              <ToastCloseButton aria-label="Close" onClick={handleClose}>
                <SVGIcon library="close" name="close" />
              </ToastCloseButton>
            </ToastHeader>
            {typeof body === 'string' ? (
              <ToastContainer dangerouslySetInnerHTML={{ __html: body }} />
            ) : (
              <ToastContainer>{body}</ToastContainer>
            )}
          </>
        ) : (
          <>
            <ToastCustomCloseButton onClick={handleClose}>
              <SVGIcon library="close" name="close" />
            </ToastCustomCloseButton>
            <ToastContainer style={{ paddingTop: '24px' }}>{children}</ToastContainer>
          </>
        )}
      </Wrapper>
    </>
  )
}

export { StyledToast }
