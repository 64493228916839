import { Theme } from '@mui/material'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const seeDetailsColor = (background: string | undefined, theme: Theme) => {
  switch (background) {
    case 'bg-dark-secondary':
      return theme.palette.custom.light.blue
    default:
      return theme.palette.custom.blue
  }
}

export const seeDetailsPositionVertical = (theme: Theme, viewtype?: IViewType) => {
  switch (viewtype) {
    default:
      return {
        [theme.breakpoints.up(769)]: {
          bottom: theme.spacing(6),
        },
        [theme.breakpoints.down(769)]: {
          bottom: theme.spacing(0),
        },
      }
  }
}

export const seeDetailsMobileBreakpoint = (viewType?: IViewType) => {
  switch (viewType) {
    case 'top-page-banner':
      return 1025
    default:
      return 769
  }
}
