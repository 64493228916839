import styled from '@mui/material/styles/styled'
import { StyledAnchor } from '../../UI'
import { IHotzoneSettings } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { Z_INDEX_LV2 } from '../../../constants/ui'
import { LinkAsButton } from '../../UI/Button/LinkAsButton'

export const ShoppableWrapper = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'ShoppableWrapper',
})(() => ({
  width: '100%',
}))

export const MediaOverlapContainer = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'Container',
})(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'contents',
  a: { height: '100%' }
}))

export const MediaOverlapContainerEffect = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'ContainerEffect',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.dark.primary,
}))

export const MediaOverlapTwoColumnContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'TwoColumnContainer',
})(({ theme }) => ({
  span: {
    [theme.breakpoints.up(0)]: {
      width: '100vw',
    },
    [theme.breakpoints.up(769)]: {
      height: theme.spacing(116),
      width: '50vw',
    },
    [theme.breakpoints.up(1025)]: {
      height: theme.spacing(145),
    },
    [theme.breakpoints.up(1281)]: {
      height: theme.spacing(163),
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}))

export const MediaOverlapFullWidthPromoContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'FullWidthPromoContainer',
})(({ theme }) => ({
  span: {
    img: {
      [theme.breakpoints.up(0)]: {
        height: theme.spacing(71.25),
      },
      [theme.breakpoints.up(769)]: {
        height: theme.spacing(116),
      },
      [theme.breakpoints.up(1025)]: {
        height: theme.spacing(145),
      },
      [theme.breakpoints.up(1281)]: {
        height: theme.spacing(163),
      },
    },
  },
}))

export const MediaOverlapLandscapePromoContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'LandscapePromoContainer',
})(({ theme }) => ({
  span: {
    img: {
      [theme.breakpoints.up(0)]: {
        height: theme.spacing(71),
      },
      [theme.breakpoints.up(769)]: {
        height: theme.spacing(104),
      },
      [theme.breakpoints.up(1281)]: {
        height: theme.spacing(117),
      },
    },
  },
}))

export const MediaOverlapTopPagePromoContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'TopPagePromoContainer',
})(({ theme }) => ({
  span: {
    img: {
      [theme.breakpoints.up(0)]: {
        height: theme.spacing(71),
      },
      [theme.breakpoints.up(769)]: {
        height: theme.spacing(116),
      },
      [theme.breakpoints.up(1025)]: {
        height: theme.spacing(80),
      },
    },
  },
}))

export const MediaOverlapProductCarouselContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'ProductCarouselContainer',
})(({ theme }) => ({
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.between('md', 'lg')]: {
    width: '80%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '60%',
    [theme.breakpoints.up(769)]: {
      width: '40%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    [theme.breakpoints.up(350)]: {
      width: '100%',
    },
  },
}))
export const MediaOverlapThreeProductCarouselContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'ThreeProductCarouselContainer',
})(({ theme }) => ({
  margin: 'auto',
  width: '370px',
  [theme.breakpoints.down(1281)]: {
    width: '331px',
  },
  [theme.breakpoints.down(1025)]: {
    width: '293px',
  },
}))
export const MediaOverlapGridOfProducts = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'GridOfProductsContainer',
})(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(76),
  margin: 'auto',
  [theme.breakpoints.down(1281)]: {
    maxWidth: theme.spacing(66),
  },
  [theme.breakpoints.between(1024, 1279)]: {
    maxWidth: theme.spacing(54),
  },
  [theme.breakpoints.between(769, 1024)]: {
    width: '40%',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '50%',
  },
  [theme.breakpoints.down(321)]: {
    maxWidth: theme.spacing(34.25),
  },
}))
export const MediaOverlapPlainSlider = styled(MediaOverlapContainer, {
  name: 'MediaOverlapV2',
  slot: 'PlainSlider',
})(({ theme }) => ({
  [theme.breakpoints.down(1441)]: {
    minWidth: theme.spacing(216),
    minHeight: theme.spacing(101.5),
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '60vw',
  },
  [theme.breakpoints.down(1281)]: {
    minWidth: theme.spacing(189.75),
  },
  [theme.breakpoints.down(1281)]: {
    minWidth: theme.spacing(189.75),
  },
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(157.5),
  },
}))

export const MediaOverlapAnchor = styled(StyledAnchor, {
  name: 'MediaOverlapV2',
  slot: 'Anchor',
  shouldForwardProp: prop => prop !== 'fullHeight',
})<{ fullHeight?: boolean }>(({ fullHeight }) => ({
  display: 'block',
  height: fullHeight ? '100%' : 'auto',
  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const MediaOverlapLinkButton = styled(LinkAsButton, {
  name: 'MediaOverlapV2',
  slot: 'LinkButton',
})(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  overflow: 'visible',
  [theme.breakpoints.between(0, 769)]: {
    width: '50%',
    minWidth: '0',
  },
}))

export const TermsAndConditionsContainer = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'TermsAndConditionsContainer',
  shouldForwardProp: prop => prop !== 'viewtype',
})(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  color: theme.palette.custom.light.blue,
  bottom: '0',
  right: '0',
  marginBottom: theme.spacing(6),
  marginRight: theme.spacing(6),
  zIndex: '1',
  display: 'flex',
  justifyContent: 'center',
  width: '105px',
  [theme.breakpoints.between(0, 769)]: {
    marginRight: '0',
    marginBottom: '8px',
    right: '20px',
    left: '50%',
    marginLeft: '-52.5px',
    textAlign: 'center',
    display: 'flex !important',
  },
}))

export const MediaTermsAndConditionsCta = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'TermsAndConditionsWrapper',
})<{
  viewtype?: IViewType
}>(({ }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'absolute',
  bottom: 0,
  width: '100%',
}))

export const MediaTermsAndConditionsCtaWhite = styled(MediaTermsAndConditionsCta, {
  name: 'MediaOverlapV2',
  slot: 'TermsAndConditionsWrapperWhite',
})<{
  viewtype?: IViewType
}>(({ theme, viewtype }) => ({
  [theme.breakpoints.up(1280)]: {
    'div > button': {
      color:
        viewtype === 'full-width-banner' || viewtype === 'landscape-banner'
          ? theme.palette.custom.light.blue
          : theme.palette.custom.blue,
    },
  },
}))

export const ShoppableContainer = styled('div', {
  name: 'Shoppable',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'viewtype' && prop !== 'hotZonesSettings',
})<{
  viewtype?: IViewType
  hotZonesSettings?: IHotzoneSettings
}>(({ theme, hotZonesSettings }) => ({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  marginTop: theme.spacing(6),
  marginLeft: theme.spacing(6),
  zIndex: '1',
  backgroundColor: !!hotZonesSettings?.lightContrast ? theme.palette.custom.white : theme.palette.custom.black,
  borderColor: !!hotZonesSettings?.lightContrast ? theme.palette.custom.light2.grey : theme.palette.custom.black,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '50vmax',
}))

export const TwoColumnShoppableContainer = styled(ShoppableContainer, {
  name: 'MediaOverlapV2',
  slot: 'TwoColumnShoppableContainer',
})(({ theme }) => ({
  [theme.breakpoints.between(769, 1025)]: {
    marginTop: theme.spacing(5.5),
    marginLeft: theme.spacing(8),
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(16),
  },
}))

/*******************\
  OVERLAY GRADIENTS
\*******************/

export const DefaultTeaserGradient = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'defaultTeaserGradient',
})(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: '1',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.8))',
}))

export const LightTeaserGradient = styled(DefaultTeaserGradient, {
  name: 'MediaOverlapV2',
  slot: 'lightTeaserGradient',
})(() => ({
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.8))',
}))

export const DefaultMobileGradient = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'defaultMobileGradient',
})(({ theme }) => ({
  [theme.breakpoints.between(0, 769)]: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: '1',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.8))',
  },
}))

export const LightMobileGradient = styled(DefaultMobileGradient, {
  name: 'MediaOverlapV2',
  slot: 'lightMobileGradient',
})(({ theme }) => ({
  [theme.breakpoints.between(0, 769)]: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.75))',
  },
}))

export const NoMobileGradient = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'noMobileGradient',
})(() => ({
  height: 'max-content',
  position: 'static',
  zIndex: 'auto',
  background: 'none',
}))

export const SquatMobileGradient = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'squatMobileGradient',
})(({ theme }) => ({
  [theme.breakpoints.between(0, 769)]: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: '1',
    background: 'linear-gradient(180deg, rgba(255,255,255,.1) -2%, rgba(0,0,0,1) 100%, rgba(0,0,0,.3) 100%)',
  },
}))

export const FullWidthMobileGradient = styled('div', {
  name: 'MediaOverlapV2',
  slot: 'fullWidthMobileGradient',
})(({ theme }) => ({
  [theme.breakpoints.up(0)]: {
    width: '100%',
    height: theme.spacing(130),
    position: 'absolute',
    zIndex: 1,
    background: 'linear-gradient(0deg, rgba(0,0,0,1) -100%, rgba(0,0,0,1) -40%, rgba(0,0,0,0) 57%)',
  },
  [theme.breakpoints.up(769)]: {
    height: 'max-content',
    position: 'static',
    zIndex: 'auto',
    background: 'none',
  },
}))
