import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
// components
import { ProductMedia } from '@components/Cms/CmsComponents-CSS/Media/ProductMedia'
import {
  ContextWrapperData,
  PlacementContext,
} from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { LinkStyled } from '@components/UI-CSS/LinkStyled'
import { ProductPrice } from '@components/UI-CSS/ProductPrice'
import { TProductPrice } from '@components/UI-CSS/ProductPrice/index.types'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { NOT_FOUND } from '@constants/routes'
import { bffProductApi } from '@features/bffProduct/query'
import useIsInViewport from '@hooks/useIsInViewport'
// types
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { Attachment } from '@typesApp/product'
// utils
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { TLightProduct } from '@utils/productNew'
// styles
import styles from './styles/index.module.scss'

type TShoppableTeaser = {
  className?: string
}

export const ShoppableTeaser: React.FC<TShoppableTeaser> = ({ className }) => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teaser = data.placement?.items?.find(isLXTeaser)
  const { hotZones, hotZonesSettings } = teaser ?? {}

  const { t } = useTranslation()
  const customerSegments = useCustomerSegmentsUtil()
  const teaserRef = useRef<HTMLDivElement | null>(null)
  const inView = useIsInViewport(teaserRef, {})

  const productPartnumberList = useMemo(
    () =>
      hotZones?.reduce<string[]>((curr, hotzone) => {
        const partnumber = hotzone.linkedContent?.externalId?.split('/').pop()
        if (partnumber) curr.push(partnumber)
        return curr
      }, []) || [],
    [hotZones]
  )
  const [getproducts, result] = bffProductApi.endpoints.getProductsByPartnumber.useLazyQuery()

  useEffect(() => {
    if (productPartnumberList.length > 0 && inView) {
      getproducts({
        partnumber: productPartnumberList,
        profile: 'light_product',
      })
    }
  }, [getproducts, inView, productPartnumberList])

  return (
    <div
      ref={teaserRef}
      className={clsx(className, styles.shoppableTeaser, {
        [styles.shoppableTeaserLight]: hotZonesSettings?.lightContrast,
      })}
    >
      <button className={styles.shoppableTeaserIcon}>
        <SVGIcon library="global" name="bag-checkout" />
      </button>

      <div className={styles.shoppableTeaserProducts}>
        {productPartnumberList?.map(partnumber => {
          const productData: TLightProduct | undefined = result.data?.[partnumber]
          if (!productData) return null

          return (
            <LinkStyled
              className={styles.shoppableTeaserLink}
              href={productData.url || NOT_FOUND}
              key={productData.partnumberId}
              variant="no-animation"
            >
              <div className={styles.shoppableTeaserImageContainer}>
                <ProductMedia
                  attachments={productData?.attachments as Attachment[]}
                  sequence={'1.0'}
                  usage={'PLP'}
                  width={200}
                  lazy={true}
                />
              </div>

              {productData.prices && (
                <ProductPrice
                  prices={productData.prices as TProductPrice['prices']}
                  customerSegments={customerSegments || []}
                  priceSetting={{
                    pattern: '$##.##',
                  }}
                  discountSetting={{
                    visible: false,
                    offLabelKey: '',
                    pattern: '',
                    type: 'percentage',
                  }}
                  strikethrough={true}
                  prefixLabel={t('ProductTile.Labels.from')}
                />
              )}
            </LinkStyled>
          )
        })}
      </div>
    </div>
  )
}
