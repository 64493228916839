import { Link } from '@components/common/Link/Link'
import clsx from 'clsx'
import { TLinkStyled } from './types'
import { onClickWithAnalytics } from '@utils/clickWithAnalytics'

export const LinkStyled: React.FC<TLinkStyled> = ({
  external,
  children,
  variant = 'primary',
  className,
  style,
  'data-element-id': data_element_id,
  'data-description': data_description,
  ...rest
}) => {
  return (
    <Link
      target={external ? '_blank' : undefined}
      className={clsx(`link-styled-${variant}`, className)}
      style={style}
      onClick={e => onClickWithAnalytics(e, undefined, data_element_id, data_description)}
      {...rest}
    >
      {children}
    </Link>
  )
}
